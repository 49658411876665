import queryString from "query-string";

const filterId = function (state, props) {
  const values = queryString.parse(props.location.search);
  return values.filterId;
};

function site(state, props) {
  if (state.site) {
    return state.site;
  }
  const values = queryString.parse(props.location.search);
  return values.site;
}

function cases(state, props) {
  const id = filterId(state, props);
  const { cases } = state.casesByFilter[id] || { cases: [] };
  return cases;
}

function products(state, props) {
  const siteId = site(state, props);
  const { products } = state.productsBySiteId[siteId] || { products: [] };
  return products;
}

function entitlements(state, props) {
  const siteId = site(state, props);
  const { entitlements } = state.entitlementsBySiteId[siteId] || { products: [] };
  return entitlements;
}

function catalog(state) {
  const catalog = state.catalog || { products: [] };
  return catalog;
}

export default {
  filterId,
  site,
  cases,
  products,
  entitlements,
  catalog,
};

import { combineReducers } from "redux";
import { createReducer, createResourceReducer } from "./create";
import * as T from "../actions/employees";

const getEmployee = createResourceReducer(
  T.GET_EMPLOYEE_LOADING,
  T.GET_EMPLOYEE_SUCCESS,
  T.GET_EMPLOYEE_FAILURE,
  T.GET_EMPLOYEE_CLEAR,
  T.GET_EMPLOYEE_PENDING,
);
const putEmployee = createResourceReducer(
  T.PUT_EMPLOYEE_LOADING,
  T.PUT_EMPLOYEE_SUCCESS,
  T.PUT_EMPLOYEE_FAILURE,
  T.PUT_EMPLOYEE_CLEAR,
);
const getEmployeeCrmPermissions = createResourceReducer(
  T.GET_EMPLOYEE_CRM_PERMISSIONS_LOADING,
  T.GET_EMPLOYEE_CRM_PERMISSIONS_SUCCESS,
  T.GET_EMPLOYEE_CRM_PERMISSIONS_FAILURE,
  T.PUT_EMPLOYEE_CRM_PERMISSIONS_CLEAR,
  T.PUT_EMPLOYEE_CRM_PERMISSIONS_PENDING,
);
const putEmployeeCrmPermissions = createResourceReducer(
  T.PUT_EMPLOYEE_CRM_PERMISSIONS_LOADING,
  T.PUT_EMPLOYEE_CRM_PERMISSIONS_SUCCESS,
  T.PUT_EMPLOYEE_CRM_PERMISSIONS_FAILURE,
  T.PUT_EMPLOYEE_CRM_PERMISSIONS_CLEAR,
);
const getEmployees = createReducer(
  T.GET_EMPLOYEES_LOADING,
  T.GET_EMPLOYEES_SUCCESS,
  T.GET_EMPLOYEES_FAILURE,
  T.GET_EMPLOYEES_CLEAR,
  T.GET_EMPLOYEES_PENDING,
);
const postEmployee = createReducer(
  T.POST_EMPLOYEE_LOADING,
  T.POST_EMPLOYEE_SUCCESS,
  T.POST_EMPLOYEE_FAILURE,
  T.POST_EMPLOYEE_CLEAR,
);

export default combineReducers({
  getEmployee,
  putEmployee,
  getEmployeeCrmPermissions,
  putEmployeeCrmPermissions,
  getEmployees,
  postEmployee,
});

import { REQUEST_CATALOG, RECEIVE_CATALOG } from "../actions";

function catalog(
  state = {
    isFetching: false,
    didInvalidate: false,
    products: [],
  },
  action,
) {
  switch (action.type) {
    case REQUEST_CATALOG:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_CATALOG:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        products: action.products,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
}

export default { catalog };

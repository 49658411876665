import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { LOCATION_CHANGE } from "connected-react-router";
import mapCases from "./map-cases";
import siteProducts from "./site-products";
import siteEntitlements from "./site-entitlements";
import catalog from "./catalog";
import identity from "./identity";
import clients from "./clients";
import products from "./products";
import employees from "./employees";
import departments from "./departments";
import upload from "./upload";
import admin from "./admin";
import crmTopics from "./crm_topics";
import crmRequests from "./crm_requests";
import codeTopics from "./ce_topics";
import codeCases from "./code_cases";
import featureFlags from "./feature_flags";

const site = (state = {}, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return Object.assign({}, state, { site: action.payload.location.pathname.split("/")[1] });
    default:
      return state;
  }
};

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    admin,
    site,
    identity,
    clients,
    products,
    employees,
    departments,
    crmTopics,
    codeTopics,
    codeCases,
    crmRequests,
    featureFlags,
    ...mapCases,
    ...siteProducts,
    ...siteEntitlements,
    ...catalog,
    ...upload,
  });

import { combineReducers } from "redux";
import { createReducer } from "./create";
import * as T from "../actions/clients";

const getClients = createReducer(T.GET_CLIENTS_LOADING, T.GET_CLIENTS_SUCCESS, T.GET_CLIENTS_FAILURE);
const getClient = createReducer(T.GET_CLIENT_LOADING, T.GET_CLIENT_SUCCESS, T.GET_CLIENT_FAILURE);

export default combineReducers({
  getClients,
  getClient,
});

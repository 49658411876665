import { combineReducers } from "redux";
import mobile from "./mobile";
import applets from "./applets";
import integrations from "./integrations";

export default combineReducers({
  mobile: combineReducers(mobile),
  applets: combineReducers(applets),
  integrations: combineReducers(integrations),
});

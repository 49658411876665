import { UPLOAD_LOADED } from "../actions/upload";

function uploadsCompleted(state = {}, action: any) {
  switch (action.type) {
    case UPLOAD_LOADED:
      return Object.assign({}, state, {
        [action.uploadId]: action.payload,
      });
    default:
      return state;
  }
}

export default { uploadsCompleted };

import { combineReducers } from "redux";
import { createResourceReducer } from "./create";
import * as F from "../actions/feature_flags";

const getEnabled = createResourceReducer(
  F.GET_FEATURE_FLAG_LOADING,
  F.GET_FEATURE_FLAG_SUCCESS,
  F.GET_FEATURE_FLAG_FAILURE,
  F.GET_FEATURE_FLAG_CLEAR,
);

export default combineReducers({
  getEnabled,
});

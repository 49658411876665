import _ from "lodash";
import { createAction, RSAARequestType, RSAASuccessType, RSAAFailureType } from "redux-api-middleware";
import AppSettings from "../config";
import networkSelectors from "../selectors/network";

export const GET_FEATURE_FLAG_LOADING = "GET_FEATURE_FLAG_LOADING";
export const GET_FEATURE_FLAG_SUCCESS = "GET_FEATURE_FLAG_SUCCESS";
export const GET_FEATURE_FLAG_FAILURE = "GET_FEATURE_FLAG_FAILURE";
export const GET_FEATURE_FLAG_PENDING = "GET_FEATURE_FLAG_PENDING";
export const GET_FEATURE_FLAG_CLEAR = "GET_FEATURE_FLAG_CLEAR";

export function getEnabled(featureName: string) {
  return (dispatch: any) =>
    dispatch(
      createAction({
        endpoint: `${AppSettings.FEATURE_FLAGS_API_URL}/${featureName}`,
        method: "GET",
        headers: networkSelectors.defaultHeaders,
        types: _.map([GET_FEATURE_FLAG_LOADING, GET_FEATURE_FLAG_SUCCESS, GET_FEATURE_FLAG_FAILURE], (type) => ({
          type,
          meta: { id: featureName },
        })) as [RSAARequestType, RSAASuccessType, RSAAFailureType],
      }),
    );
}

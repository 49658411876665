import queryString from "query-string";
import { createAction } from "redux-api-middleware";
import AppSettings from "../config";
import networkSelectors from "../selectors/network";

export const GET_CODE_CASE_IMAGES_LOADING = "GET_CODE_CASE_IMAGES_LOADING";
export const GET_CODE_CASE_IMAGES_SUCCESS = "GET_CODE_CASE_IMAGES_SUCCESS";
export const GET_CODE_CASE_IMAGES_FAILURE = "GET_CODE_CASE_IMAGES_FAILURE";
export const GET_CODE_CASE_PROPERTIES_LOADING = "GET_CODE_CASE_PROPERTIES_LOADING";
export const GET_CODE_CASE_PROPERTIES_SUCCESS = "GET_CODE_CASE_PROPERTIES_SUCCESS";
export const GET_CODE_CASE_PROPERTIES_FAILURE = "GET_CODE_CASE_PROPERTIES_FAILURE";

export function getCodeCaseImages(query: any) {
  return createAction({
    endpoint: `${AppSettings.GOGOV_BASE_API_URL}/core/ce/cases?has_attachment=true&${queryString.stringify(query, {
      arrayFormat: "comma",
    })}`,
    method: "GET",
    headers: networkSelectors.defaultHeaders,
    types: [GET_CODE_CASE_IMAGES_LOADING, GET_CODE_CASE_IMAGES_SUCCESS, GET_CODE_CASE_IMAGES_FAILURE],
  });
}

export function getCodeCaseProperties(query: any) {
  return createAction({
    endpoint: `${AppSettings.GOGOV_BASE_API_URL}/core/ce/properties?${queryString.stringify(query, {
      arrayFormat: "comma",
    })}`,
    method: "GET",
    headers: networkSelectors.defaultHeaders,
    types: [GET_CODE_CASE_PROPERTIES_LOADING, GET_CODE_CASE_PROPERTIES_SUCCESS, GET_CODE_CASE_PROPERTIES_FAILURE],
  });
}

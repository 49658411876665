import _ from "lodash";
import { createAction, RSAARequestType, RSAASuccessType, RSAAFailureType } from "redux-api-middleware";
import AppSettings from "../config";
import networkSelectors from "../selectors/network";

export const GET_DEPARTMENTS_LOADING = "GET_DEPARTMENTS_LOADING";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_FAILURE = "GET_DEPARTMENTS_FAILURE";
export const GET_DEPARTMENT_PERMISSIONS_LOADING = "GET_DEPARTMENT_PERMISSIONS_LOADING";
export const GET_DEPARTMENT_PERMISSIONS_SUCCESS = "GET_DEPARTMENT_PERMISSIONS_SUCCESS";
export const GET_DEPARTMENT_PERMISSIONS_FAILURE = "GET_DEPARTMENT_PERMISSIONS_FAILURE";

export function getDepartments() {
  return createAction({
    endpoint: `${AppSettings.DEPARTMENTS_API_URL}`,
    method: "GET",
    headers: networkSelectors.defaultHeaders,
    types: [GET_DEPARTMENTS_LOADING, GET_DEPARTMENTS_SUCCESS, GET_DEPARTMENTS_FAILURE],
  });
}

export function getDepartmentPermissions(id: string) {
  return createAction({
    endpoint: `${AppSettings.DEPARTMENTS_API_URL}/${id}/permissions`,
    method: "GET",
    headers: networkSelectors.defaultHeaders,
    types: _.map(
      [GET_DEPARTMENT_PERMISSIONS_LOADING, GET_DEPARTMENT_PERMISSIONS_SUCCESS, GET_DEPARTMENT_PERMISSIONS_FAILURE],
      (type) => ({ type, meta: { id } }),
    ) as [RSAARequestType, RSAASuccessType, RSAAFailureType],
  });
}

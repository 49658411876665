import _ from "lodash";
import queryString from "query-string";
import { createAction, RSAARequestType, RSAASuccessType, RSAAFailureType } from "redux-api-middleware";
import decamelizeKeysDeep from "decamelize-keys-deep";
import AppSettings from "../config";
import networkSelectors from "../selectors/network";

export const GET_EMPLOYEE_LOADING = "GET_EMPLOYEE_LOADING";
export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS";
export const GET_EMPLOYEE_FAILURE = "GET_EMPLOYEE_FAILURE";
export const GET_EMPLOYEE_PENDING = "GET_EMPLOYEE_PENDING";
export const GET_EMPLOYEE_CLEAR = "GET_EMPLOYEE_CLEAR";
export const PUT_EMPLOYEE_LOADING = "PUT_EMPLOYEE_LOADING";
export const PUT_EMPLOYEE_SUCCESS = "PUT_EMPLOYEE_SUCCESS";
export const PUT_EMPLOYEE_FAILURE = "PUT_EMPLOYEE_FAILURE";
export const PUT_EMPLOYEE_CLEAR = "PUT_EMPLOYEE_CLEAR";
export const GET_EMPLOYEE_CRM_PERMISSIONS_LOADING = "GET_EMPLOYEE_CRM_PERMISSIONS_LOADING";
export const GET_EMPLOYEE_CRM_PERMISSIONS_SUCCESS = "GET_EMPLOYEE_CRM_PERMISSIONS_SUCCESS";
export const GET_EMPLOYEE_CRM_PERMISSIONS_FAILURE = "GET_EMPLOYEE_CRM_PERMISSIONS_FAILURE";
export const GET_EMPLOYEE_CRM_PERMISSIONS_CLEAR = "GET_EMPLOYEE_CRM_PERMISSIONS_CLEAR";
export const GET_EMPLOYEE_CRM_PERMISSIONS_PENDING = "GET_EMPLOYEE_CRM_PERMISSIONS_PENDING";
export const PUT_EMPLOYEE_CRM_PERMISSIONS_LOADING = "PUT_EMPLOYEE_CRM_PERMISSIONS_LOADING";
export const PUT_EMPLOYEE_CRM_PERMISSIONS_SUCCESS = "PUT_EMPLOYEE_CRM_PERMISSIONS_SUCCESS";
export const PUT_EMPLOYEE_CRM_PERMISSIONS_FAILURE = "PUT_EMPLOYEE_CRM_PERMISSIONS_FAILURE";
export const PUT_EMPLOYEE_CRM_PERMISSIONS_PENDING = "PUT_EMPLOYEE_CRM_PERMISSIONS_PENDING";
export const PUT_EMPLOYEE_CRM_PERMISSIONS_CLEAR = "PUT_EMPLOYEE_CRM_PERMISSIONS_CLEAR";
export const GET_EMPLOYEE_CE_PERMISSIONS_LOADING = "GET_EMPLOYEE_CE_PERMISSIONS_LOADING";
export const GET_EMPLOYEE_CE_PERMISSIONS_SUCCESS = "GET_EMPLOYEE_CE_PERMISSIONS_SUCCESS";
export const GET_EMPLOYEE_CE_PERMISSIONS_FAILURE = "GET_EMPLOYEE_CE_PERMISSIONS_FAILURE";
export const GET_EMPLOYEE_CE_PERMISSIONS_CLEAR = "GET_EMPLOYEE_CE_PERMISSIONS_CLEAR";
export const GET_EMPLOYEE_CE_PERMISSIONS_PENDING = "GET_EMPLOYEE_CE_PERMISSIONS_PENDING";
export const PUT_EMPLOYEE_CE_PERMISSIONS_LOADING = "PUT_EMPLOYEE_CE_PERMISSIONS_LOADING";
export const PUT_EMPLOYEE_CE_PERMISSIONS_SUCCESS = "PUT_EMPLOYEE_CE_PERMISSIONS_SUCCESS";
export const PUT_EMPLOYEE_CE_PERMISSIONS_FAILURE = "PUT_EMPLOYEE_CE_PERMISSIONS_FAILURE";
export const PUT_EMPLOYEE_CE_PERMISSIONS_PENDING = "PUT_EMPLOYEE_CE_PERMISSIONS_PENDING";
export const PUT_EMPLOYEE_CE_PERMISSIONS_CLEAR = "PUT_EMPLOYEE_CE_PERMISSIONS_CLEAR";
export const GET_EMPLOYEES_LOADING = "GET_EMPLOYEES_LOADING";
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEES_FAILURE = "GET_EMPLOYEES_FAILURE";
export const GET_EMPLOYEES_CLEAR = "GET_EMPLOYEES_CLEAR";
export const GET_EMPLOYEES_PENDING = "GET_EMPLOYEES_PENDING";
export const POST_EMPLOYEE_LOADING = "POST_EMPLOYEE_LOADING";
export const POST_EMPLOYEE_SUCCESS = "POST_EMPLOYEE_SUCCESS";
export const POST_EMPLOYEE_FAILURE = "POST_EMPLOYEE_FAILURE";
export const POST_EMPLOYEE_CLEAR = "POST_EMPLOYEE_CLEAR";

export function getEmployee(id: string) {
  return (dispatch: any) =>
    dispatch(
      createAction({
        endpoint: `${AppSettings.EMPLOYEES_API_URL}/${id}`,
        method: "GET",
        headers: networkSelectors.defaultHeaders,
        types: _.map([GET_EMPLOYEE_LOADING, GET_EMPLOYEE_SUCCESS, GET_EMPLOYEE_FAILURE], (type) => ({
          type,
          meta: { id },
        })) as [RSAARequestType, RSAASuccessType, RSAAFailureType],
      }),
    );
}

export function putEmployee(id: string, body: any) {
  return createAction({
    endpoint: `${AppSettings.EMPLOYEES_API_URL}/${id}`,
    method: "PUT",
    body: JSON.stringify(decamelizeKeysDeep({ data: { attributes: body } }, "-")),
    headers: networkSelectors.defaultHeaders,
    types: _.map([PUT_EMPLOYEE_LOADING, PUT_EMPLOYEE_SUCCESS, PUT_EMPLOYEE_FAILURE], (type) => ({
      type,
      meta: { id },
    })) as [RSAARequestType, RSAASuccessType, RSAAFailureType],
  });
}

export function getEmployeeCrmPermissions(id: string) {
  return (dispatch: any) =>
    dispatch(
      createAction({
        endpoint: `${AppSettings.EMPLOYEES_API_URL}/${id}/crm_permissions`,
        method: "GET",
        headers: networkSelectors.defaultHeaders,
        types: _.map(
          [
            GET_EMPLOYEE_CRM_PERMISSIONS_LOADING,
            GET_EMPLOYEE_CRM_PERMISSIONS_SUCCESS,
            GET_EMPLOYEE_CRM_PERMISSIONS_FAILURE,
          ],
          (type) => ({ type, meta: { id } }),
        ) as [RSAARequestType, RSAASuccessType, RSAAFailureType],
      }),
    );
}

export function getEmployeeCrmPermissionsClear(id: string) {
  return (dispatch: any) =>
    dispatch({
      type: GET_EMPLOYEE_CRM_PERMISSIONS_CLEAR,
      meta: { id },
    });
}

export function putEmployeeCrmPermissions(id: string, body: any) {
  return createAction({
    endpoint: `${AppSettings.EMPLOYEES_API_URL}/${id}/crm_permissions`,
    method: "PUT",
    body: JSON.stringify(decamelizeKeysDeep({ data: { attributes: body } }, "-")),
    headers: networkSelectors.defaultHeaders,
    types: _.map(
      [
        PUT_EMPLOYEE_CRM_PERMISSIONS_LOADING,
        PUT_EMPLOYEE_CRM_PERMISSIONS_SUCCESS,
        PUT_EMPLOYEE_CRM_PERMISSIONS_FAILURE,
      ],
      (type) => ({ type, meta: { id } }),
    ) as [RSAARequestType, RSAASuccessType, RSAAFailureType],
  });
}

export function putEmployeeCrmPermissionsClear(id: string) {
  return (dispatch: any) =>
    dispatch({
      type: PUT_EMPLOYEE_CRM_PERMISSIONS_CLEAR,
      meta: { id },
    });
}

export function getEmployeeCePermissions(id: string) {
  return (dispatch: any) =>
    dispatch(
      createAction({
        endpoint: `${AppSettings.EMPLOYEES_API_URL}/${id}/ce_permissions`,
        method: "GET",
        headers: networkSelectors.defaultHeaders,
        types: _.map(
          [
            GET_EMPLOYEE_CE_PERMISSIONS_LOADING,
            GET_EMPLOYEE_CE_PERMISSIONS_SUCCESS,
            GET_EMPLOYEE_CE_PERMISSIONS_FAILURE,
          ],
          (type) => ({ type, meta: { id } }),
        ) as [RSAARequestType, RSAASuccessType, RSAAFailureType],
      }),
    );
}

export function putEmployeeCePermissions(id: string, body: any) {
  return createAction({
    endpoint: `${AppSettings.EMPLOYEES_API_URL}/${id}/ce_permissions`,
    method: "PUT",
    body: JSON.stringify(decamelizeKeysDeep({ data: { attributes: body } }, "-")),
    headers: networkSelectors.defaultHeaders,
    types: _.map(
      [PUT_EMPLOYEE_CE_PERMISSIONS_LOADING, PUT_EMPLOYEE_CE_PERMISSIONS_SUCCESS, PUT_EMPLOYEE_CE_PERMISSIONS_FAILURE],
      (type) => ({ type, meta: { id } }),
    ) as [RSAARequestType, RSAASuccessType, RSAAFailureType],
  });
}

export function putEmployeeCePermissionsClear(id: string) {
  return (dispatch: any) =>
    dispatch({
      type: PUT_EMPLOYEE_CE_PERMISSIONS_CLEAR,
      meta: { id },
    });
}

export function getEmployeeClear(id: string) {
  return (dispatch: any) =>
    dispatch({
      type: GET_EMPLOYEE_CLEAR,
      meta: { id },
    });
}

export function putEmployeeClear(id: string) {
  return (dispatch: any) =>
    dispatch({
      type: PUT_EMPLOYEE_CLEAR,
      meta: { id },
    });
}

interface IEmployeesListQuery {
  includeDeactivated?: boolean;
}
export function getEmployees(employeesListQuery?: IEmployeesListQuery) {
  return (dispatch: any) =>
    dispatch(
      createAction({
        endpoint: `${AppSettings.EMPLOYEES_API_URL}?${queryString.stringify(
          decamelizeKeysDeep(employeesListQuery || {}, "_"),
          { arrayFormat: "bracket" },
        )}`,
        method: "GET",
        headers: networkSelectors.defaultHeaders,
        types: [GET_EMPLOYEES_LOADING, GET_EMPLOYEES_SUCCESS, GET_EMPLOYEES_FAILURE],
      }),
    );
}

export function getEmployeesClear() {
  return (dispatch: any) =>
    dispatch({
      type: GET_EMPLOYEES_CLEAR,
    });
}

export function postEmployee(body: any) {
  return createAction({
    endpoint: `${AppSettings.EMPLOYEES_API_URL}`,
    method: "POST",
    body: JSON.stringify(decamelizeKeysDeep({ data: { attributes: body } }, "-")),
    headers: networkSelectors.defaultHeaders,
    types: [POST_EMPLOYEE_LOADING, POST_EMPLOYEE_SUCCESS, POST_EMPLOYEE_FAILURE],
  });
}

export function postEmployeeClear() {
  return (dispatch: any) =>
    dispatch({
      type: POST_EMPLOYEE_CLEAR,
    });
}

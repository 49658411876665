import React from "react";
import { Flag, FlagNameValues, Popup } from "semantic-ui-react";
import { countryFullNames } from "../../constants/CountryCodesMap";

/**
 * Renders country flag given a country code (ISO 2 code, e.g. US, CA, etc.)
 *
 * @param countryId - ISO 2 code of the country
 *
 * @returns
 */
export function CountryFlag({ countryId }: { countryId: string }) {
  if (!countryId) return null;
  const countryLabel = countryFullNames[countryId] ?? countryId;
  return <Popup content={countryLabel} trigger={<Flag name={countryId.toLowerCase() as FlagNameValues} />} />;
}

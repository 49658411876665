import { combineReducers } from "redux";
import { createResourceReducer } from "./create";
import * as T from "../queries/products";

const getProducts = createResourceReducer(
  T.GET_PRODUCTS_LOADING,
  T.GET_PRODUCTS_SUCCESS,
  T.GET_PRODUCTS_FAILURE,
  T.GET_PRODUCTS_CLEAR,
  T.GET_PRODUCTS_PENDING,
);

export default combineReducers({
  getProducts,
});

import { REQUEST_CASES, RECEIVE_CASES } from "../actions";

function cases(
  state = {
    isFetching: false,
    didInvalidate: false,
    cases: [],
  },
  action,
) {
  switch (action.type) {
    case REQUEST_CASES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_CASES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        cases: action.cases,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
}

function casesByFilter(state = {}, action) {
  switch (action.type) {
    case RECEIVE_CASES:
    case REQUEST_CASES:
      return Object.assign({}, state, {
        [action.filterId]: cases(state[action.filterId], action),
      });
    default:
      return state;
  }
}

export default { casesByFilter };

import { createAction } from "redux-api-middleware";
import AppSettings from "../../config";
import networkSelectors from "../../selectors/network";

export const GET_APPLET_TYPES_LOADING = "APPLET_TYPES_LOADING";
export const GET_APPLET_TYPES_SUCCESS = "APPLET_TYPES_SUCCESS";
export const GET_APPLET_TYPES_FAILURE = "APPLET_TYPES_FAILURE";

export const appletTypes = () => {
  return createAction({
    endpoint: `${AppSettings.ADMIN_API_URL}/applets`,
    method: "GET",
    headers: networkSelectors.defaultHeaders,
    types: [GET_APPLET_TYPES_LOADING, GET_APPLET_TYPES_SUCCESS, GET_APPLET_TYPES_FAILURE],
  });
};

import identitySelectors from "../selectors/identity";

export default ({ getState }: { getState: any }) => {
  return (next: any) => (action: any) => {
    const state = getState();
    const shouldRedirectToLogin =
      localStorage.getItem("jwt.access_token") && action.error && action.payload.status === 401;
    const shouldRemoveToken = identitySelectors.isTokenExpired(state);
    if (shouldRemoveToken) {
      localStorage.removeItem("jwt.access_token");
    }
    if (shouldRedirectToLogin) {
      localStorage.removeItem("jwt.access_token");
      window.location.href = `/${state.site.site}/login`;
    }
    return next(action);
  };
};

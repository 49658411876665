import _ from "lodash";
import { createAction } from "redux-api-middleware";
import AppSettings from "../config";

export const GET_PRODUCTS_LOADING = "GET_PRODUCTS_LOADING";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";
export const GET_PRODUCTS_PENDING = "GET_PRODUCTS_PENDING";
export const GET_PRODUCTS_CLEAR = "GET_PRODUCTS_CLEAR";

export const hasProduct = (queryResult: any, productName: string) =>
  !!_.get(queryResult.data, "payload.data", []).find((product: any) => product.attributes.name === productName);

/*
 * This would only work if the user has a cookie
 */
export const fetchGetProducts = (dispatch: any) => (key: any, site: string) => {
  return dispatch(
    createAction({
      endpoint: `${AppSettings.PRODUCTS_API_URL}/sites/${site}/products`,
      method: "GET",
      types: [GET_PRODUCTS_LOADING, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAILURE],
    }),
  );
};

import * as M from "../../actions/admin/mobile";
import { createReducer } from "../create";

const applets = createReducer(M.GET_APPLETS_LOADING, M.GET_APPLETS_SUCCESS, M.GET_APPLETS_FAILURE);
const putApplets = createReducer(
  M.PUT_APPLETS_LOADING,
  M.PUT_APPLETS_SUCCESS,
  M.PUT_APPLETS_FAILURE,
  M.PUT_APPLETS_CLEAR,
);
const branding = createReducer(M.GET_BRANDING_LOADING, M.GET_BRANDING_SUCCESS, M.GET_BRANDING_FAILURE);
const putBranding = createReducer(
  M.PUT_BRANDING_LOADING,
  M.PUT_BRANDING_SUCCESS,
  M.PUT_BRANDING_FAILURE,
  M.PUT_BRANDING_CLEAR,
);
const settings = createReducer(M.GET_SETTINGS_LOADING, M.GET_SETTINGS_SUCCESS, M.GET_SETTINGS_FAILURE);
const putSettings = createReducer(
  M.PUT_SETTINGS_LOADING,
  M.PUT_SETTINGS_SUCCESS,
  M.PUT_SETTINGS_FAILURE,
  M.PUT_SETTINGS_CLEAR,
);
const iconData = createReducer(M.GET_ICON_DATA_LOADING, M.GET_ICON_DATA_SUCCESS, M.GET_ICON_DATA_FAILURE);
const getPublish = createReducer(M.GET_PUBLISH_LOADING, M.GET_PUBLISH_SUCCESS, M.GET_PUBLISH_FAILURE);
const putPublish = createReducer(
  M.PUT_PUBLISH_LOADING,
  M.PUT_PUBLISH_SUCCESS,
  M.PUT_PUBLISH_FAILURE,
  M.PUT_PUBLISH_CLEAR,
);
const postBuild = createReducer(M.POST_BUILD_LOADING, M.POST_BUILD_SUCCESS, M.POST_BUILD_FAILURE, M.POST_BUILD_CLEAR);

export default {
  applets,
  putApplets,
  settings,
  putSettings,
  branding,
  putBranding,
  iconData,
  getPublish,
  putPublish,
  postBuild,
};

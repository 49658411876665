import fetch from "cross-fetch";
import AppSettings from "../config";

export const REQUEST_CASES = "REQUEST_CASES";
export const REQUEST_PRODUCTS = "REQUEST_PRODUCTS";
export const REQUEST_ENTITLEMENTS = "REQUEST_ENTITLEMENTS";
export const REQUEST_CATALOG = "REQUEST_CATALOG";
export const RECEIVE_CASES = "RECEIVE_CASES";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const RECEIVE_ENTITLEMENTS = "RECEIVE_ENTITLEMENTS";
export const RECEIVE_CATALOG = "RECEIVE_CATALOG";
export const BUY_PRODUCT = "BUY_PRODUCT";

function requestCases(filterId) {
  return {
    type: REQUEST_CASES,
    filterId,
  };
}

function requestProducts(siteId) {
  return {
    type: REQUEST_PRODUCTS,
    siteId,
  };
}

function requestEntitlements(siteId) {
  return {
    type: REQUEST_ENTITLEMENTS,
    siteId,
  };
}

function requestCatalog() {
  return {
    type: REQUEST_CATALOG,
  };
}

function receiveCases(filterId, json) {
  return {
    type: RECEIVE_CASES,
    filterId,
    cases: json.data,
    receivedAt: Date.now(),
  };
}

function receiveProducts(siteId, json) {
  return {
    type: RECEIVE_PRODUCTS,
    siteId,
    products: json.data,
    receivedAt: Date.now(),
  };
}

function receiveEntitlements(siteId, json) {
  return {
    type: RECEIVE_ENTITLEMENTS,
    siteId,
    entitlements: json.data,
    receivedAt: Date.now(),
  };
}

function receiveCatalog(json) {
  return {
    type: RECEIVE_CATALOG,
    products: json.data,
    receivedAt: Date.now(),
  };
}

function receiveReceipt(dispatch, json) {
  dispatch(fetchEntitlements());
  return {
    type: RECEIVE_PRODUCTS,
    products: json.data,
    receivedAt: Date.now(),
  };
}

function fetchCases(filterId) {
  return (dispatch) => {
    dispatch(requestCases(filterId));
    let options = {
      credentials: "include",
    };
    return fetch(`${AppSettings.GOGOV_WEB_API_URL}/api/filter_map.php?filterid=${filterId}`, options)
      .then((response) => response.json())
      .then((json) => dispatch(receiveCases(filterId, json)));
  };
}

function fetchProducts(siteId) {
  return (dispatch) => {
    dispatch(requestProducts());
    let options = {
      credentials: "include",
    };
    return fetch(`${AppSettings.GOGOV_WEB_API_URL}/api/products.php`, options)
      .then((response) => response.json())
      .then((json) => dispatch(receiveProducts(siteId, json)));
  };
}

function fetchEntitlements(siteId) {
  return (dispatch) => {
    dispatch(requestEntitlements());
    let options = {
      credentials: "include",
    };
    return fetch(`${AppSettings.GOGOV_WEB_API_URL}/api/entitlements.php`, options)
      .then((response) => response.json())
      .then((json) => dispatch(receiveEntitlements(siteId, json)));
  };
}

function fetchCatalog() {
  return (dispatch) => {
    dispatch(requestCatalog());
    let options = {
      credentials: "include",
    };

    return fetch(`${AppSettings.GOGOV_WEB_API_URL}/api/catalog.php`, options)
      .then((response) => response.json())
      .then((json) => dispatch(receiveCatalog(json)));
  };
}

function shouldFetchCases(state, filterId) {
  const cases = state.casesByFilter[filterId];
  if (!cases) {
    return true;
  } else if (cases.isFetching) {
    return false;
  } else {
    return cases.didInvalidate;
  }
}

function shouldFetchProducts(state, site) {
  const products = state.productsBySiteId[site];
  if (!products) {
    return true;
  } else if (products.isFetching) {
    return false;
  } else {
    return products.didInvalidate;
  }
}

function shouldFetchEntitlements(state, site) {
  const entitlements = state.entitlementsBySiteId[site];
  if (!entitlements) {
    return true;
  } else if (entitlements.isFetching) {
    return false;
  } else {
    return entitlements.didInvalidate;
  }
}

function shouldFetchCatalog(state) {
  const catalog = state.catalog;
  if (!catalog) {
    return true;
  } else if (catalog.isFetching) {
    return false;
  } else if (catalog.products.length === 0) {
    return true;
  }
  return catalog.didInvalidate;
}

export function fetchCasesIfNeeded(filterId) {
  return (dispatch, getState) => {
    if (shouldFetchCases(getState(), filterId)) {
      return dispatch(fetchCases(filterId));
    }
  };
}

export function fetchProductsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchProducts(getState())) {
      return dispatch(fetchProducts());
    }
  };
}

export function fetchEntitlementsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchEntitlements(getState())) {
      return dispatch(fetchEntitlements());
    }
  };
}

export function fetchCatalogIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchCatalog(getState())) {
      return dispatch(fetchCatalog());
    }
  };
}

function doBuyProduct(productId) {
  return (dispatch) => {
    dispatch({ type: BUY_PRODUCT, productId });
    let body = {
      product_id: productId,
    };
    let options = {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(body),
    };
    return fetch(`${AppSettings.GOGOV_WEB_API_URL}/api/products.php`, options)
      .then((response) => response.json())
      .then((json) => dispatch(receiveReceipt(dispatch, json)));
  };
}

export function buyProduct(productId) {
  return (dispatch) => {
    return dispatch(doBuyProduct(productId));
  };
}

import { createAction } from "redux-api-middleware";
import AppSettings from "../config";
import networkSelectors from "../selectors/network";

export const GET_CE_TOPICS_LOADING = "GET_CE_TOPICS_LOADING";
export const GET_CE_TOPICS_SUCCESS = "GET_CE_TOPICS_SUCCESS";
export const GET_CE_TOPICS_FAILURE = "GET_CE_TOPICS_FAILURE";
export const GET_CE_TOPICS_CLEAR = "GET_CE_TOPICS_CLEAR";

export function getCeTopics() {
  return createAction({
    endpoint: `${AppSettings.GOGOV_BASE_API_URL}/core/ce/topics`,
    method: "GET",
    headers: networkSelectors.defaultHeaders,
    types: [GET_CE_TOPICS_LOADING, GET_CE_TOPICS_SUCCESS, GET_CE_TOPICS_FAILURE],
  });
}

export function getCeTopicsClear() {
  return (dispatch: any) =>
    dispatch({
      type: GET_CE_TOPICS_CLEAR,
    });
}

import { REQUEST_ENTITLEMENTS, RECEIVE_ENTITLEMENTS } from "../actions";

function entitlements(
  state = {
    isFetching: false,
    didInvalidate: false,
    entitlements: [],
  },
  action,
) {
  switch (action.type) {
    case REQUEST_ENTITLEMENTS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_ENTITLEMENTS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        entitlements: action.entitlements,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
}

function entitlementsBySiteId(state = {}, action) {
  switch (action.type) {
    case RECEIVE_ENTITLEMENTS:
    case REQUEST_ENTITLEMENTS:
      return Object.assign({}, state, {
        [action.siteId]: entitlements(state[action.siteId], action),
      });
    default:
      return state;
  }
}

export default { entitlementsBySiteId };

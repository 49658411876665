interface ApplicationURLObject {
  search: string,
  pathname: string
}

const usePermitsApplicationListUrl = () => {

  const getSearch = (): string => {
    const urlObject = getApplicationUrlObject()
    return urlObject.search;
  }

  const getPathname = (): string => {
    const urlObject = getApplicationUrlObject()
    return urlObject.pathname
  }

  const urlExists = (): boolean => {
    const url = localStorage.getItem("applicationURL");
    return url !== null;
  }

  const getApplicationUrlObject = (): ApplicationURLObject => {
    const applicationUrlObject = { search: "", pathname: "" }

    const url = localStorage.getItem("applicationURL");
    const urlExists = url !== null;
    if (!urlExists) {
      return applicationUrlObject
    }

    const applicationUrlJson = urlExists ? JSON.parse(url) : ""
    applicationUrlObject.search = applicationUrlJson?.search
    applicationUrlObject.pathname = applicationUrlJson?.pathname

    return applicationUrlObject
  }

  return {
    getSearch,
    getPathname,
    urlExists
  }
}

export default usePermitsApplicationListUrl
export function createResourceReducer(
  loading: string,
  success: string,
  failure: string,
  clear?: string,
  pending?: string,
) {
  return (state = {}, action: any) => {
    if (!action || !action.meta || !action.meta.id) {
      return state;
    }
    switch (action.type) {
      case pending:
        return Object.assign({}, state, { [action.meta.id]: { pending: true } });
      case loading:
        return Object.assign({}, state, { [action.meta.id]: { pending: true, loading: true } });
      case success:
        return Object.assign({}, state, {
          [action.meta.id]: { pending: false, loading: false, payload: action.payload, error: undefined },
        });
      case failure:
        return Object.assign({}, state, {
          [action.meta.id]: { pending: false, loading: false, payload: undefined, error: action.payload },
        });
      case clear:
        return {};
    }
    return state;
  };
}
export function createReducer(loading: string, success: string, failure: string, clear?: string, pending?: string) {
  return (state = {}, action: any) => {
    switch (action.type) {
      case pending:
        return Object.assign({}, state, { pending: true });
      case loading:
        return Object.assign({}, state, { pending: true, loading: true });
      case success:
        return Object.assign({}, state, { pending: false, loading: false, payload: action.payload, error: undefined });
      case failure:
        return Object.assign({}, state, { pending: false, loading: false, payload: undefined, error: action.payload });
      case clear:
        return {};
    }
    return state;
  };
}

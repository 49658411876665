import identitySelectors from "./identity";

const can = (permission: string) => (state: any) => {
  if (identitySelectors.permissions(state) === "*") return true;
  if (identitySelectors.permissions(state) === null) return false;
  if (identitySelectors.permissions(state) === undefined) return false;
  return identitySelectors.permissions(state).includes(permission);
};

export default {
  can,
};

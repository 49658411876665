import { combineReducers } from "redux";
import * as T from "../actions/crm_requests";

const crmRequestImages = (state = {}, action: any) => {
  switch (action.type) {
    case T.GET_CRM_REQUEST_IMAGES_SUCCESS:
      const reducer = (accum: any, item: any) => {
        accum[item.id] = item.attributes["primary-attachment-url"];
        return accum;
      };
      const reduced = action.payload.data.reduce(reducer, {});
      return Object.assign({}, state, reduced);
    default:
      return state;
  }
};

export default combineReducers({
  crmRequestImages,
});

import { createAction } from "redux-api-middleware";
import AppSettings from "../../config";
import networkSelectors from "../../selectors/network";

export const GET_INTEGRATIONS_LOADING = "GET_INTEGRATIONS_LOADING";
export const GET_INTEGRATIONS_SUCCESS = "GET_INTEGRATIONS_SUCCESS";
export const GET_INTEGRATIONS_FAILURE = "GET_INTEGRATIONS_FAILURE";
export const POST_INTEGRATION_LOADING = "POST_INTEGRATION_LOADING";
export const POST_INTEGRATION_SUCCESS = "POST_INTEGRATION_SUCCESS";
export const POST_INTEGRATION_FAILURE = "POST_INTEGRATION_FAILURE";
export const POST_INTEGRATION_CLEAR = "POST_INTEGRATION_CLEAR";

export function getIntegrations() {
  return createAction({
    endpoint: `${AppSettings.ADMIN_API_URL}/integrations`,
    method: "GET",
    headers: networkSelectors.defaultHeaders,
    types: [GET_INTEGRATIONS_LOADING, GET_INTEGRATIONS_SUCCESS, GET_INTEGRATIONS_FAILURE],
  });
}

export function postIntegration(body: any) {
  return createAction({
    endpoint: `${AppSettings.ADMIN_API_URL}/integrations`,
    method: "POST",
    body: JSON.stringify(body),
    headers: networkSelectors.defaultHeaders,
    types: [POST_INTEGRATION_LOADING, POST_INTEGRATION_SUCCESS, POST_INTEGRATION_FAILURE],
  });
}

export function postIntegrationClear() {
  return (dispatch: any) =>
    dispatch({
      type: POST_INTEGRATION_CLEAR,
    });
}

export default {
  CRM: "CRM",
  CE: "CE",
  COMMUNICATIONS: "Communications",
  PERMITS: "Permits",
};

/**
 * Product as a const object
 * Allows for safer enum alternative
 * Not replacing export default to
 * prevent app from breaking elsewhere
 */
export const Products = {
  CRM: "CRM",
  CE: "CE",
  COMMUNICATIONS: "Communications",
  PERMITS: "Permits",
} as const;

/**
 * ProductType
 * Union type of Product type values
 * safer alternative to enum
 */
export type ProductType = (typeof Products)[keyof typeof Products];

/**
 * ProductRoutesMap
 * Map of default routes for the various
 * products
 */
export const ProductRoutesMap = new Map<ProductType, string>([
  ["CRM", "crm"],
  ["CE", "code"],
  ["Communications", "communications"],
  ["Permits", "permits/applications"],
]);

import React from "react";
import { useHistory } from "react-router-dom";
import { Image, Button } from "semantic-ui-react";
import AppSettings from "../../config";
import "./Page404.scss";

export const Page404 = () => {
  const history = useHistory();
  return (
    <div className='page-404-wrapper'>
      <Image size='large' src={`${AppSettings.ASSETS_URL}/static/error-404-page-not-found.png`} />
      <div className='left-side-text'>
        <h1>This page could not be found</h1>
        <p> Sorry, the page you are looking for does not exist</p>
        <Button primary content='Go Back' onClick={() => history.goBack()} />
      </div>
    </div>
  );
};

export default Page404;

export const CountryRegionCodes = [
  {
    callingCode: 247,
    regionCode: "AC",
    countryName: "Ascension Island",
  },
  {
    callingCode: 376,
    regionCode: "AD",
    countryName: "Andorra",
  },
  {
    callingCode: 971,
    regionCode: "AE",
    countryName: "United Arab Emirates",
  },
  {
    callingCode: 93,
    regionCode: "AF",
    countryName: "Afghanistan",
  },
  {
    callingCode: 1,
    regionCode: "AG",
    countryName: "Antigua and Barbuda",
  },
  {
    callingCode: 1,
    regionCode: "AI",
    countryName: "Anguilla",
  },
  {
    callingCode: 355,
    regionCode: "AL",
    countryName: "Albania",
  },
  {
    callingCode: 374,
    regionCode: "AM",
    countryName: "Armenia",
  },
  {
    callingCode: 244,
    regionCode: "AO",
    countryName: "Angola",
  },
  {
    callingCode: 54,
    regionCode: "AR",
    countryName: "Argentina",
  },
  {
    callingCode: 1,
    regionCode: "AS",
    countryName: "American Samoa",
  },
  {
    callingCode: 43,
    regionCode: "AT",
    countryName: "Austria",
  },
  {
    callingCode: 61,
    regionCode: "AU",
    countryName: "Australia",
  },
  {
    callingCode: 297,
    regionCode: "AW",
    countryName: "Aruba",
  },
  {
    callingCode: 358,
    regionCode: "AX",
    countryName: "Aland Islands",
  },
  {
    callingCode: 994,
    regionCode: "AZ",
    countryName: "Azerbaijan",
  },
  {
    callingCode: 387,
    regionCode: "BA",
    countryName: "Bosnia and Herzegovina",
  },
  {
    callingCode: 1,
    regionCode: "BB",
    countryName: "Barbados",
  },
  {
    callingCode: 880,
    regionCode: "BD",
    countryName: "Bangladesh",
  },
  {
    callingCode: 32,
    regionCode: "BE",
    countryName: "Belgium",
  },
  {
    callingCode: 226,
    regionCode: "BF",
    countryName: "Burkina Faso",
  },
  {
    callingCode: 359,
    regionCode: "BG",
    countryName: "Bulgaria",
  },
  {
    callingCode: 973,
    regionCode: "BH",
    countryName: "Bahrain",
  },
  {
    callingCode: 257,
    regionCode: "BI",
    countryName: "Burundi",
  },
  {
    callingCode: 229,
    regionCode: "BJ",
    countryName: "Benin",
  },
  {
    callingCode: 590,
    regionCode: "BL",
    countryName: "Saint Barthelemy",
  },
  {
    callingCode: 1,
    regionCode: "BM",
    countryName: "Bermuda",
  },
  {
    callingCode: 673,
    regionCode: "BN",
    countryName: "Brunei",
  },
  {
    callingCode: 591,
    regionCode: "BO",
    countryName: "Bolivia",
  },
  {
    callingCode: 599,
    regionCode: "BQ",
    countryName: "Bonaire, Saint Eustatius and Saba ",
  },
  {
    callingCode: 55,
    regionCode: "BR",
    countryName: "Brazil",
  },
  {
    callingCode: 1,
    regionCode: "BS",
    countryName: "Bahamas",
  },
  {
    callingCode: 975,
    regionCode: "BT",
    countryName: "Bhutan",
  },
  {
    callingCode: 267,
    regionCode: "BW",
    countryName: "Botswana",
  },
  {
    callingCode: 375,
    regionCode: "BY",
    countryName: "Belarus",
  },
  {
    callingCode: 501,
    regionCode: "BZ",
    countryName: "Belize",
  },
  {
    callingCode: 1,
    regionCode: "CA",
    countryName: "Canada",
  },
  {
    callingCode: 61,
    regionCode: "CC",
    countryName: "Cocos Islands",
  },
  {
    callingCode: 243,
    regionCode: "CD",
    countryName: "Democratic Republic of the Congo",
  },
  {
    callingCode: 236,
    regionCode: "CF",
    countryName: "Central African Republic",
  },
  {
    callingCode: 242,
    regionCode: "CG",
    countryName: "Republic of the Congo",
  },
  {
    callingCode: 41,
    regionCode: "CH",
    countryName: "Switzerland",
  },
  {
    callingCode: 225,
    regionCode: "CI",
    countryName: "Ivory Coast",
  },
  {
    callingCode: 682,
    regionCode: "CK",
    countryName: "Cook Islands",
  },
  {
    callingCode: 56,
    regionCode: "CL",
    countryName: "Chile",
  },
  {
    callingCode: 237,
    regionCode: "CM",
    countryName: "Cameroon",
  },
  {
    callingCode: 86,
    regionCode: "CN",
    countryName: "China",
  },
  {
    callingCode: 57,
    regionCode: "CO",
    countryName: "Colombia",
  },
  {
    callingCode: 506,
    regionCode: "CR",
    countryName: "Costa Rica",
  },
  {
    callingCode: 53,
    regionCode: "CU",
    countryName: "Cuba",
  },
  {
    callingCode: 238,
    regionCode: "CV",
    countryName: "Cape Verde",
  },
  {
    callingCode: 599,
    regionCode: "CW",
    countryName: "Curacao",
  },
  {
    callingCode: 61,
    regionCode: "CX",
    countryName: "Christmas Island",
  },
  {
    callingCode: 357,
    regionCode: "CY",
    countryName: "Cyprus",
  },
  {
    callingCode: 420,
    regionCode: "CZ",
    countryName: "Czech Republic",
  },
  {
    callingCode: 49,
    regionCode: "DE",
    countryName: "Germany",
  },
  {
    callingCode: 253,
    regionCode: "DJ",
    countryName: "Djibouti",
  },
  {
    callingCode: 45,
    regionCode: "DK",
    countryName: "Denmark",
  },
  {
    callingCode: 1,
    regionCode: "DM",
    countryName: "Dominica",
  },
  {
    callingCode: 1,
    regionCode: "DO",
    countryName: "Dominican Republic",
  },
  {
    callingCode: 213,
    regionCode: "DZ",
    countryName: "Algeria",
  },
  {
    callingCode: 593,
    regionCode: "EC",
    countryName: "Ecuador",
  },
  {
    callingCode: 372,
    regionCode: "EE",
    countryName: "Estonia",
  },
  {
    callingCode: 20,
    regionCode: "EG",
    countryName: "Egypt",
  },
  {
    callingCode: 212,
    regionCode: "EH",
    countryName: "Western Sahara",
  },
  {
    callingCode: 291,
    regionCode: "ER",
    countryName: "Eritrea",
  },
  {
    callingCode: 34,
    regionCode: "ES",
    countryName: "Spain",
  },
  {
    callingCode: 251,
    regionCode: "ET",
    countryName: "Ethiopia",
  },
  {
    callingCode: 358,
    regionCode: "FI",
    countryName: "Finland",
  },
  {
    callingCode: 679,
    regionCode: "FJ",
    countryName: "Fiji",
  },
  {
    callingCode: 500,
    regionCode: "FK",
    countryName: "Falkland Islands",
  },
  {
    callingCode: 691,
    regionCode: "FM",
    countryName: "Micronesia",
  },
  {
    callingCode: 298,
    regionCode: "FO",
    countryName: "Faroe Islands",
  },
  {
    callingCode: 33,
    regionCode: "FR",
    countryName: "France",
  },
  {
    callingCode: 241,
    regionCode: "GA",
    countryName: "Gabon",
  },
  {
    callingCode: 44,
    regionCode: "GB",
    countryName: "United Kingdom",
  },
  {
    callingCode: 1,
    regionCode: "GD",
    countryName: "Grenada",
  },
  {
    callingCode: 995,
    regionCode: "GE",
    countryName: "Georgia",
  },
  {
    callingCode: 594,
    regionCode: "GF",
    countryName: "French Guiana",
  },
  {
    callingCode: 44,
    regionCode: "GG",
    countryName: "Guernsey",
  },
  {
    callingCode: 233,
    regionCode: "GH",
    countryName: "Ghana",
  },
  {
    callingCode: 350,
    regionCode: "GI",
    countryName: "Gibraltar",
  },
  {
    callingCode: 299,
    regionCode: "GL",
    countryName: "Greenland",
  },
  {
    callingCode: 220,
    regionCode: "GM",
    countryName: "Gambia",
  },
  {
    callingCode: 224,
    regionCode: "GN",
    countryName: "Guinea",
  },
  {
    callingCode: 590,
    regionCode: "GP",
    countryName: "Guadeloupe",
  },
  {
    callingCode: 240,
    regionCode: "GQ",
    countryName: "Equatorial Guinea",
  },
  {
    callingCode: 30,
    regionCode: "GR",
    countryName: "Greece",
  },
  {
    callingCode: 502,
    regionCode: "GT",
    countryName: "Guatemala",
  },
  {
    callingCode: 1,
    regionCode: "GU",
    countryName: "Guam",
  },
  {
    callingCode: 245,
    regionCode: "GW",
    countryName: "Guinea-Bissau",
  },
  {
    callingCode: 592,
    regionCode: "GY",
    countryName: "Guyana",
  },
  {
    callingCode: 852,
    regionCode: "HK",
    countryName: "Hong Kong",
  },
  {
    callingCode: 504,
    regionCode: "HN",
    countryName: "Honduras",
  },
  {
    callingCode: 385,
    regionCode: "HR",
    countryName: "Croatia",
  },
  {
    callingCode: 509,
    regionCode: "HT",
    countryName: "Haiti",
  },
  {
    callingCode: 36,
    regionCode: "HU",
    countryName: "Hungary",
  },
  {
    callingCode: 62,
    regionCode: "ID",
    countryName: "Indonesia",
  },
  {
    callingCode: 353,
    regionCode: "IE",
    countryName: "Ireland",
  },
  {
    callingCode: 972,
    regionCode: "IL",
    countryName: "Israel",
  },
  {
    callingCode: 44,
    regionCode: "IM",
    countryName: "Isle of Man",
  },
  {
    callingCode: 91,
    regionCode: "IN",
    countryName: "India",
  },
  {
    callingCode: 246,
    regionCode: "IO",
    countryName: "British Indian Ocean Territory",
  },
  {
    callingCode: 964,
    regionCode: "IQ",
    countryName: "Iraq",
  },
  {
    callingCode: 98,
    regionCode: "IR",
    countryName: "Iran",
  },
  {
    callingCode: 354,
    regionCode: "IS",
    countryName: "Iceland",
  },
  {
    callingCode: 39,
    regionCode: "IT",
    countryName: "Italy",
  },
  {
    callingCode: 44,
    regionCode: "JE",
    countryName: "Jersey",
  },
  {
    callingCode: 1,
    regionCode: "JM",
    countryName: "Jamaica",
  },
  {
    callingCode: 962,
    regionCode: "JO",
    countryName: "Jordan",
  },
  {
    callingCode: 81,
    regionCode: "JP",
    countryName: "Japan",
  },
  {
    callingCode: 254,
    regionCode: "KE",
    countryName: "Kenya",
  },
  {
    callingCode: 996,
    regionCode: "KG",
    countryName: "Kyrgyzstan",
  },
  {
    callingCode: 855,
    regionCode: "KH",
    countryName: "Cambodia",
  },
  {
    callingCode: 686,
    regionCode: "KI",
    countryName: "Kiribati",
  },
  {
    callingCode: 269,
    regionCode: "KM",
    countryName: "Comoros",
  },
  {
    callingCode: 1,
    regionCode: "KN",
    countryName: "Saint Kitts and Nevis",
  },
  {
    callingCode: 850,
    regionCode: "KP",
    countryName: "North Korea",
  },
  {
    callingCode: 82,
    regionCode: "KR",
    countryName: "South Korea",
  },
  {
    callingCode: 965,
    regionCode: "KW",
    countryName: "Kuwait",
  },
  {
    callingCode: 1,
    regionCode: "KY",
    countryName: "Cayman Islands",
  },
  {
    callingCode: 7,
    regionCode: "KZ",
    countryName: "Kazakhstan",
  },
  {
    callingCode: 856,
    regionCode: "LA",
    countryName: "Laos",
  },
  {
    callingCode: 961,
    regionCode: "LB",
    countryName: "Lebanon",
  },
  {
    callingCode: 1,
    regionCode: "LC",
    countryName: "Saint Lucia",
  },
  {
    callingCode: 423,
    regionCode: "LI",
    countryName: "Liechtenstein",
  },
  {
    callingCode: 94,
    regionCode: "LK",
    countryName: "Sri Lanka",
  },
  {
    callingCode: 231,
    regionCode: "LR",
    countryName: "Liberia",
  },
  {
    callingCode: 266,
    regionCode: "LS",
    countryName: "Lesotho",
  },
  {
    callingCode: 370,
    regionCode: "LT",
    countryName: "Lithuania",
  },
  {
    callingCode: 352,
    regionCode: "LU",
    countryName: "Luxembourg",
  },
  {
    callingCode: 371,
    regionCode: "LV",
    countryName: "Latvia",
  },
  {
    callingCode: 218,
    regionCode: "LY",
    countryName: "Libya",
  },
  {
    callingCode: 212,
    regionCode: "MA",
    countryName: "Morocco",
  },
  {
    callingCode: 377,
    regionCode: "MC",
    countryName: "Monaco",
  },
  {
    callingCode: 373,
    regionCode: "MD",
    countryName: "Moldova",
  },
  {
    callingCode: 382,
    regionCode: "ME",
    countryName: "Montenegro",
  },
  {
    callingCode: 590,
    regionCode: "MF",
    countryName: "Saint Martin",
  },
  {
    callingCode: 261,
    regionCode: "MG",
    countryName: "Madagascar",
  },
  {
    callingCode: 692,
    regionCode: "MH",
    countryName: "Marshall Islands",
  },
  {
    callingCode: 389,
    regionCode: "MK",
    countryName: "Macedonia",
  },
  {
    callingCode: 223,
    regionCode: "ML",
    countryName: "Mali",
  },
  {
    callingCode: 95,
    regionCode: "MM",
    countryName: "Myanmar",
  },
  {
    callingCode: 976,
    regionCode: "MN",
    countryName: "Mongolia",
  },
  {
    callingCode: 853,
    regionCode: "MO",
    countryName: "Macao",
  },
  {
    callingCode: 1,
    regionCode: "MP",
    countryName: "Northern Mariana Islands",
  },
  {
    callingCode: 596,
    regionCode: "MQ",
    countryName: "Martinique",
  },
  {
    callingCode: 222,
    regionCode: "MR",
    countryName: "Mauritania",
  },
  {
    callingCode: 1,
    regionCode: "MS",
    countryName: "Montserrat",
  },
  {
    callingCode: 356,
    regionCode: "MT",
    countryName: "Malta",
  },
  {
    callingCode: 230,
    regionCode: "MU",
    countryName: "Mauritius",
  },
  {
    callingCode: 960,
    regionCode: "MV",
    countryName: "Maldives",
  },
  {
    callingCode: 265,
    regionCode: "MW",
    countryName: "Malawi",
  },
  {
    callingCode: 52,
    regionCode: "MX",
    countryName: "Mexico",
  },
  {
    callingCode: 60,
    regionCode: "MY",
    countryName: "Malaysia",
  },
  {
    callingCode: 258,
    regionCode: "MZ",
    countryName: "Mozambique",
  },
  {
    callingCode: 264,
    regionCode: "NA",
    countryName: "Namibia",
  },
  {
    callingCode: 687,
    regionCode: "NC",
    countryName: "New Caledonia",
  },
  {
    callingCode: 227,
    regionCode: "NE",
    countryName: "Niger",
  },
  {
    callingCode: 672,
    regionCode: "NF",
    countryName: "Norfolk Island",
  },
  {
    callingCode: 234,
    regionCode: "NG",
    countryName: "Nigeria",
  },
  {
    callingCode: 505,
    regionCode: "NI",
    countryName: "Nicaragua",
  },
  {
    callingCode: 31,
    regionCode: "NL",
    countryName: "Netherlands",
  },
  {
    callingCode: 47,
    regionCode: "NO",
    countryName: "Norway",
  },
  {
    callingCode: 977,
    regionCode: "NP",
    countryName: "Nepal",
  },
  {
    callingCode: 674,
    regionCode: "NR",
    countryName: "Nauru",
  },
  {
    callingCode: 683,
    regionCode: "NU",
    countryName: "Niue",
  },
  {
    callingCode: 64,
    regionCode: "NZ",
    countryName: "New Zealand",
  },
  {
    callingCode: 968,
    regionCode: "OM",
    countryName: "Oman",
  },
  {
    callingCode: 507,
    regionCode: "PA",
    countryName: "Panama",
  },
  {
    callingCode: 51,
    regionCode: "PE",
    countryName: "Peru",
  },
  {
    callingCode: 689,
    regionCode: "PF",
    countryName: "French Polynesia",
  },
  {
    callingCode: 675,
    regionCode: "PG",
    countryName: "Papua New Guinea",
  },
  {
    callingCode: 63,
    regionCode: "PH",
    countryName: "Philippines",
  },
  {
    callingCode: 92,
    regionCode: "PK",
    countryName: "Pakistan",
  },
  {
    callingCode: 48,
    regionCode: "PL",
    countryName: "Poland",
  },
  {
    callingCode: 508,
    regionCode: "PM",
    countryName: "Saint Pierre and Miquelon",
  },
  {
    callingCode: 1,
    regionCode: "PR",
    countryName: "Puerto Rico",
  },
  {
    callingCode: 970,
    regionCode: "PS",
    countryName: "Palestinian Territory",
  },
  {
    callingCode: 351,
    regionCode: "PT",
    countryName: "Portugal",
  },
  {
    callingCode: 680,
    regionCode: "PW",
    countryName: "Palau",
  },
  {
    callingCode: 595,
    regionCode: "PY",
    countryName: "Paraguay",
  },
  {
    callingCode: 974,
    regionCode: "QA",
    countryName: "Qatar",
  },
  {
    callingCode: 262,
    regionCode: "RE",
    countryName: "Reunion",
  },
  {
    callingCode: 40,
    regionCode: "RO",
    countryName: "Romania",
  },
  {
    callingCode: 381,
    regionCode: "RS",
    countryName: "Serbia",
  },
  {
    callingCode: 7,
    regionCode: "RU",
    countryName: "Russia",
  },
  {
    callingCode: 250,
    regionCode: "RW",
    countryName: "Rwanda",
  },
  {
    callingCode: 966,
    regionCode: "SA",
    countryName: "Saudi Arabia",
  },
  {
    callingCode: 677,
    regionCode: "SB",
    countryName: "Solomon Islands",
  },
  {
    callingCode: 248,
    regionCode: "SC",
    countryName: "Seychelles",
  },
  {
    callingCode: 249,
    regionCode: "SD",
    countryName: "Sudan",
  },
  {
    callingCode: 46,
    regionCode: "SE",
    countryName: "Sweden",
  },
  {
    callingCode: 65,
    regionCode: "SG",
    countryName: "Singapore",
  },
  {
    callingCode: 290,
    regionCode: "SH",
    countryName: "Saint Helena",
  },
  {
    callingCode: 386,
    regionCode: "SI",
    countryName: "Slovenia",
  },
  {
    callingCode: 47,
    regionCode: "SJ",
    countryName: "Svalbard and Jan Mayen",
  },
  {
    callingCode: 421,
    regionCode: "SK",
    countryName: "Slovakia",
  },
  {
    callingCode: 232,
    regionCode: "SL",
    countryName: "Sierra Leone",
  },
  {
    callingCode: 378,
    regionCode: "SM",
    countryName: "San Marino",
  },
  {
    callingCode: 221,
    regionCode: "SN",
    countryName: "Senegal",
  },
  {
    callingCode: 252,
    regionCode: "SO",
    countryName: "Somalia",
  },
  {
    callingCode: 597,
    regionCode: "SR",
    countryName: "Suriname",
  },
  {
    callingCode: 211,
    regionCode: "SS",
    countryName: "South Sudan",
  },
  {
    callingCode: 239,
    regionCode: "ST",
    countryName: "Sao Tome and Principe",
  },
  {
    callingCode: 503,
    regionCode: "SV",
    countryName: "El Salvador",
  },
  {
    callingCode: 1,
    regionCode: "SX",
    countryName: "Sint Maarten",
  },
  {
    callingCode: 963,
    regionCode: "SY",
    countryName: "Syria",
  },
  {
    callingCode: 268,
    regionCode: "SZ",
    countryName: "Swaziland",
  },
  {
    callingCode: 1,
    regionCode: "TC",
    countryName: "Turks and Caicos Islands",
  },
  {
    callingCode: 235,
    regionCode: "TD",
    countryName: "Chad",
  },
  {
    callingCode: 228,
    regionCode: "TG",
    countryName: "Togo",
  },
  {
    callingCode: 66,
    regionCode: "TH",
    countryName: "Thailand",
  },
  {
    callingCode: 992,
    regionCode: "TJ",
    countryName: "Tajikistan",
  },
  {
    callingCode: 690,
    regionCode: "TK",
    countryName: "Tokelau",
  },
  {
    callingCode: 670,
    regionCode: "TL",
    countryName: "East Timor",
  },
  {
    callingCode: 993,
    regionCode: "TM",
    countryName: "Turkmenistan",
  },
  {
    callingCode: 216,
    regionCode: "TN",
    countryName: "Tunisia",
  },
  {
    callingCode: 676,
    regionCode: "TO",
    countryName: "Tonga",
  },
  {
    callingCode: 90,
    regionCode: "TR",
    countryName: "Turkey",
  },
  {
    callingCode: 1,
    regionCode: "TT",
    countryName: "Trinidad and Tobago",
  },
  {
    callingCode: 688,
    regionCode: "TV",
    countryName: "Tuvalu",
  },
  {
    callingCode: 886,
    regionCode: "TW",
    countryName: "Taiwan",
  },
  {
    callingCode: 255,
    regionCode: "TZ",
    countryName: "Tanzania",
  },
  {
    callingCode: 380,
    regionCode: "UA",
    countryName: "Ukraine",
  },
  {
    callingCode: 256,
    regionCode: "UG",
    countryName: "Uganda",
  },
  {
    callingCode: 1,
    regionCode: "US",
    countryName: "United States",
  },
  {
    callingCode: 598,
    regionCode: "UY",
    countryName: "Uruguay",
  },
  {
    callingCode: 998,
    regionCode: "UZ",
    countryName: "Uzbekistan",
  },
  {
    callingCode: 39,
    regionCode: "VA",
    countryName: "Vatican",
  },
  {
    callingCode: 1,
    regionCode: "VC",
    countryName: "Saint Vincent and the Grenadines",
  },
  {
    callingCode: 58,
    regionCode: "VE",
    countryName: "Venezuela",
  },
  {
    callingCode: 1,
    regionCode: "VG",
    countryName: "British Virgin Islands",
  },
  {
    callingCode: 1,
    regionCode: "VI",
    countryName: "U.S. Virgin Islands",
  },
  {
    callingCode: 84,
    regionCode: "VN",
    countryName: "Vietnam",
  },
  {
    callingCode: 678,
    regionCode: "VU",
    countryName: "Vanuatu",
  },
  {
    callingCode: 681,
    regionCode: "WF",
    countryName: "Wallis and Futuna",
  },
  {
    callingCode: 685,
    regionCode: "WS",
    countryName: "Samoa",
  },
  {
    callingCode: 383,
    regionCode: "XK",
    countryName: "Kosovo",
  },
  {
    callingCode: 967,
    regionCode: "YE",
    countryName: "Yemen",
  },
  {
    callingCode: 262,
    regionCode: "YT",
    countryName: "Mayotte",
  },
  {
    callingCode: 27,
    regionCode: "ZA",
    countryName: "South Africa",
  },
  {
    callingCode: 260,
    regionCode: "ZM",
    countryName: "Zambia",
  },
  {
    callingCode: 263,
    regionCode: "ZW",
    countryName: "Zimbabwe",
  },
] as const;

import { combineReducers } from "redux";
import { createReducer } from "./create";
import * as T from "../actions/ce_topics";

const getCeTopics = createReducer(
  T.GET_CE_TOPICS_LOADING,
  T.GET_CE_TOPICS_SUCCESS,
  T.GET_CE_TOPICS_FAILURE,
  T.GET_CE_TOPICS_CLEAR,
);

export default combineReducers({
  getCeTopics,
});

import React from "react";
import ColorHash from "color-hash";
import "./ContactAvatar.scss";
import { PermitsContact } from "../types/PermitsContact";
import ContactConnectedProfilePopup from "../../contacts/components/ContactConnectedProfilePopup";

type ContactAvatarProps =
  | {
      firstName: string;
      lastName: string;
      fullName?: string;
      contact?: PermitsContact;
      linkToConnectedCitizen?: boolean;
      size?: string;
      fontScale?: string;
      ariaLabelPrefix?: string;
    }
  | {
      firstName?: string;
      lastName?: string;
      fullName: string;
      contact?: PermitsContact;
      linkToConnectedCitizen?: boolean;
      size?: string;
      fontScale?: string;
      ariaLabelPrefix?: string;
    }
  | {
      firstName?: string;
      lastName?: string;
      fullName?: string;
      contact: PermitsContact;
      linkToConnectedCitizen?: boolean;
      size?: string;
      fontScale?: string;
      ariaLabelPrefix?: string;
    };

export const ContactAvatar = ({
  firstName,
  lastName,
  fullName,
  contact,
  linkToConnectedCitizen,
  size,
  fontScale,
  ariaLabelPrefix,
}: ContactAvatarProps) => {
  let name: null | string = null;
  if (contact) name = `${contact.firstName?.trim()} ${contact.lastName?.trim()}`.trim();
  if (firstName || lastName) name = `${firstName?.trim()} ${lastName?.trim()}`.trim();
  if (fullName) name = fullName.trim();

  name = name ?? "Default";

  const initials = name
    ?.split(" ")
    .map((n) => n?.[0]?.toUpperCase() ?? "")
    .join("");

  const colorHash = new ColorHash();
  const color: string = colorHash.hex(name);
  const ariaLabel = ariaLabelPrefix ? `${ariaLabelPrefix}${initials}` : undefined;

  const avatar = (
    <div aria-label={ariaLabel}>
      <div
        className='contact-avatar'
        style={{
          backgroundColor: color,
          width: size ?? "32px",
          height: size ?? "32px",
          fontSize: fontScale ?? "90%",
        }}
      >
        <span>{initials}</span>
      </div>
    </div>
  );

  if (linkToConnectedCitizen) return <ContactConnectedProfilePopup contact={contact} trigger={avatar} />;
  return avatar;
};

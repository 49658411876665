import { REQUEST_PRODUCTS, RECEIVE_PRODUCTS } from "../actions";

function products(
  state = {
    isFetching: false,
    didInvalidate: false,
    products: [],
  },
  action,
) {
  switch (action.type) {
    case REQUEST_PRODUCTS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_PRODUCTS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        products: action.products,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
}

function productsBySiteId(state = {}, action) {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
    case REQUEST_PRODUCTS:
      return Object.assign({}, state, {
        [action.siteId]: products(state[action.siteId], action),
      });
    default:
      return state;
  }
}

export default { productsBySiteId };

import {
  GET_APPLET_TYPES_LOADING,
  GET_APPLET_TYPES_SUCCESS,
  GET_APPLET_TYPES_FAILURE,
} from "../../actions/admin/applets";
import { createReducer } from "../create";

const getAppletTypes = createReducer(GET_APPLET_TYPES_LOADING, GET_APPLET_TYPES_SUCCESS, GET_APPLET_TYPES_FAILURE);

export default {
  getAppletTypes,
};

/**
 * Retrieves flag emoji given a country code (ISO 2 code, e.g. US, CA, etc.)
 *
 * @param countryCode
 * @returns
 */
export function getFlagEmoji(countryCode: string) {
  return countryCode
    .toUpperCase()
    .split("")
    .map((char) => String.fromCodePoint(127397 + char.charCodeAt(0)))
    .reduce((a, b) => `${a}${b}`);
}

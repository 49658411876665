import { createAction } from "redux-api-middleware";
import AppSettings from "../config";
import networkSelectors from "../selectors/network";

export const GET_CLIENTS_LOADING = "GET_CLIENTS_LOADING";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAILURE = "GET_CLIENTS_FAILURE";
export const GET_CLIENT_LOADING = "GET_CLIENT_LOADING";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const GET_CLIENT_FAILURE = "GET_CLIENT_FAILURE";
export const GET_IDENTITY_LOADING = "GET_IDENTITY_LOADING";
export const GET_IDENTITY_SUCCESS = "GET_IDENTITY_SUCCESS";
export const GET_IDENTITY_FAILURE = "GET_IDENTITY_FAILURE";

export function getClients() {
  return createAction({
    endpoint: `${AppSettings.CLIENTS_API_URL}/`,
    method: "GET",
    headers: networkSelectors.defaultHeaders,
    types: [GET_CLIENTS_LOADING, GET_CLIENTS_SUCCESS, GET_CLIENTS_FAILURE],
  });
}

export function getClient(site: string) {
  return createAction({
    endpoint: `${AppSettings.CLIENTS_API_URL}/${site}`,
    method: "GET",
    headers: networkSelectors.defaultHeaders,
    types: [GET_CLIENT_LOADING, GET_CLIENT_SUCCESS, GET_CLIENT_FAILURE],
  });
}

export function getIdentity() {
  return createAction({
    endpoint: `${AppSettings.IDENTITY_API_URL}/me`,
    method: "GET",
    headers: networkSelectors.defaultHeaders,
    types: [GET_IDENTITY_LOADING, GET_IDENTITY_SUCCESS, GET_IDENTITY_FAILURE],
  });
}

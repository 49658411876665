import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchReleaseNotes } from "../queries/release_notes";
import networkSelectors from "../selectors/network";
import { useSelector } from "react-redux";

/**
 * The releases notes selector can be a CSS selector or null.
 */
type ReleasesNotesSelector = string | null;

/**
 * Methods and fields for interacting with our releases notes
 */
interface ReleaseNotesContextType {
  /**
   * Our releases notes account (gogov.releasenotes.io)
   */
  account: string;
  /**
   * @returns a function that opens the release notes modal
   */
  open: () => void;
  /**
   * @returns a function that closes the releaes notes modal
   */
  close: () => void;
  /**
   * The number of unseen release notes
   */
  unseenCount: number;
  /**
   * @returns a function that removes the releases notes badge
   */
  clearBadge: () => void;
}

const RELEASE_NOTES_SITE = "gogov.releasenotes.io";
const RELEASE_NOTES_SELECTOR: ReleasesNotesSelector = null;

const ReleaseNotesContext = createContext<ReleaseNotesContextType | null>(null);

export const ReleaseNotesContextProvider = ({ children }: { children: any }) => {
  const [account] = useState(RELEASE_NOTES_SITE);
  const [selector] = useState<ReleasesNotesSelector>(RELEASE_NOTES_SELECTOR);
  const [unseenCount, setUnseenCount] = useState(0);

  const networkHeaders = useSelector(networkSelectors.defaultHeaders);

  const getJwt = (url: string) => {
    const jwtStart = url.indexOf("=") + 1;
    return url.slice(jwtStart);
  };

  /**
   * Opens the releases notes modal. This is actually documented on the releases notes
   * website. The modal can be manually opened by calling "window.rnw("show")".
   */
  const open = () => {
    const win = window as any;
    if (win.rnw) {
      win.rnw("show");
    }
  };

  /**
   * Manually used to close the releases notes modal
   */
  const close = () => {
    const win = window as any;
    if (win.rnw) {
      win.rnw("hide");
    }
  };

  /**
   * Clears the red alert badge indicating how many unseen releases someone has seen.
   *
   * @details
   *
   * The releases notes script calculates the number of unseen releases based on the
   * last time someone has opened the releases notes modal. The value "rnw-last-opened"
   */
  const clearBadge = () => {
    localStorage.setItem("rnw-last-opened", new Date().getTime().toString());
    (window as any).rnw.unseen_count = 0;
    setUnseenCount(0);
  };

  /**
   * Gets the number of unseen releases.
   *
   * @details
   *
   * The release notes script keeps track of the number of unseen releases using the global
   * variable "window.rnw.unseen_count". The variable gets computed and set when the script
   * from the releases notes site is run.
   *
   * The number of unseen releases is calculated based on a variable kept in localstorage
   * called "rnw-last-opened".
   *
   * @returns the number of unseen releases
   */
  const getActualUnseenCount = () => {
    const win = window as any;
    let count = win?.rnw?.unseen_count;
    if (typeof count !== "number") {
      count = 0;
    }
    return count;
  };

  /**
   * Pulls in the releases notes script that builds the modal and sets the badges. This
   * should only be run once.
   */
  useEffect(() => {
    fetchReleaseNotes(networkHeaders)().then((body) => {
      /**
       * This is for our release notes page. It pulls in a script to initialize some stuff for the release notes badge and modal,
       * I'm pretty sure. Here's the link @see https://app.releasenotes.io/installation
       */
      (function (window: Window, document: Document, scriptId: string, scriptSource: string) {
        (window as any)["ReleaseNotesWidget"] = scriptId;
        (window as any)[scriptId] = function (...args: any[]) {
          ((window as any)[scriptId].q = (window as any)[scriptId].q || []).push(args);
        };

        const js = document.createElement("script"),
          fjs = document.getElementsByTagName("script")[0];
        js.id = scriptId;
        js.src = scriptSource;
        js.async = true;
        if (fjs.parentNode) fjs.parentNode.insertBefore(js, fjs);
      })(window, document, "rnw", "https://s3.amazonaws.com/cdn.releasenotes.io/v1/bootstrap.js");

      // Get the jwt to pass to release notes. We need to pass this to the call to 'init'
      const jwt = getJwt(body.url) as string;

      // Initialize the release notes window
      (window as any).rnw("init", {
        account: account,
        selector: selector,
        jwt: jwt,
      });

      // Set the unseen count - should be able to get it from the localstorage field
      setUnseenCount(getActualUnseenCount());
    });
  }, []);

  /**
   * The releases notes script will update the number of unseen releases at a point in
   * time we can't exactly hook into. To keep our state up-to-date, we can just poll
   * every couple seconds to check if the value has changed.
   */
  useEffect(() => {
    const POLL_INTERVAL = 2000;
    const handleId = setInterval(() => {
      setUnseenCount(getActualUnseenCount());
    }, POLL_INTERVAL);

    return () => {
      clearInterval(handleId);
    };
  });

  return (
    <ReleaseNotesContext.Provider
      value={{ account: account, open: open, close: close, unseenCount: unseenCount, clearBadge: clearBadge }}
    >
      {children}
    </ReleaseNotesContext.Provider>
  );
};

export const useReleaseNotes = () => {
  const releaseNotes = useContext(ReleaseNotesContext);
  if (releaseNotes === null) {
    throw new Error("Not in release notes context!");
  }
  return releaseNotes;
};

import queryString from "query-string";
import { createAction } from "redux-api-middleware";
import AppSettings from "../config";
import networkSelectors from "../selectors/network";

export const GET_CRM_REQUEST_IMAGES_LOADING = "GET_CRM_REQUEST_IMAGES_LOADING";
export const GET_CRM_REQUEST_IMAGES_SUCCESS = "GET_CRM_REQUEST_IMAGES_SUCCESS";
export const GET_CRM_REQUEST_IMAGES_FAILURE = "GET_CRM_REQUEST_IMAGES_FAILURE";

export function getCrmRequestImages(query: any) {
  return createAction({
    endpoint: `${AppSettings.CRM_REQUESTS_API_URL}?has_attachment=true&${queryString.stringify(query, {
      arrayFormat: "bracket",
    })}`,
    method: "GET",
    headers: networkSelectors.defaultHeaders,
    types: [GET_CRM_REQUEST_IMAGES_LOADING, GET_CRM_REQUEST_IMAGES_SUCCESS, GET_CRM_REQUEST_IMAGES_FAILURE],
  });
}

import _ from "lodash";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { fetchGetFeatureFlags } from "../queries/feature_flags";

export function useFeatureFlag(featureName: string) {
  const dispatch = useDispatch();
  const featureFlagQuery = useQuery(["featureFlags", featureName], fetchGetFeatureFlags(dispatch));
  const isEnabled = useMemo(() => _.get(featureFlagQuery, "data.payload.enabled"), [featureFlagQuery.data]);
  return { isEnabled, ...featureFlagQuery };
}

import { combineReducers } from "redux";
import * as T from "../actions/code_cases";

const codeCaseImages = (state = {}, action: any) => {
  switch (action.type) {
    case T.GET_CODE_CASE_IMAGES_SUCCESS:
      const reducer = (accum: any, item: any) => {
        accum[item.id] = {
          primaryAttachmentUrl: item.attributes["primary-attachment-url"],
          primaryThumbnailUrl: item.attributes["primary-thumbnail-url"],
        };
        return accum;
      };
      const reduced = action.payload.data.reduce(reducer, {});
      return Object.assign({}, state, reduced);
    default:
      return state;
  }
};

const codeCaseProperties = (state: any = { loading: false }, action: any) => {
  switch (action.type) {
    case T.GET_CODE_CASE_PROPERTIES_LOADING:
      return Object.assign({}, state, { loading: true, data: state.data });
    case T.GET_CODE_CASE_PROPERTIES_SUCCESS:
      const reducer = (accum: any, item: any) => {
        accum[item.id] = item.attributes;
        return accum;
      };
      const reduced = action.payload.data.reduce(reducer, state.data || {});
      return Object.assign({}, state, { loading: false, data: reduced });
    default:
      return state;
  }
};
export default combineReducers({
  codeCaseImages,
  codeCaseProperties,
});

import { combineReducers } from "redux";
import { createReducer } from "./create";
import * as T from "../actions/crm_topics";

const getCrmTopics = createReducer(
  T.GET_CRM_TOPICS_LOADING,
  T.GET_CRM_TOPICS_SUCCESS,
  T.GET_CRM_TOPICS_FAILURE,
  T.GET_CRM_TOPICS_CLEAR,
);

export default combineReducers({
  getCrmTopics,
});

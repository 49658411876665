import { combineReducers } from "redux";
import { createReducer, createResourceReducer } from "./create";
import * as T from "../actions/departments";

const getDepartments = createReducer(T.GET_DEPARTMENTS_LOADING, T.GET_DEPARTMENTS_SUCCESS, T.GET_DEPARTMENTS_FAILURE);
const getDepartmentPermissions = createResourceReducer(
  T.GET_DEPARTMENT_PERMISSIONS_LOADING,
  T.GET_DEPARTMENT_PERMISSIONS_SUCCESS,
  T.GET_DEPARTMENT_PERMISSIONS_FAILURE,
);

export default combineReducers({
  getDepartments,
  getDepartmentPermissions,
});

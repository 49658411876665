import {
  GET_INTEGRATIONS_LOADING,
  GET_INTEGRATIONS_SUCCESS,
  GET_INTEGRATIONS_FAILURE,
  POST_INTEGRATION_LOADING,
  POST_INTEGRATION_SUCCESS,
  POST_INTEGRATION_FAILURE,
  POST_INTEGRATION_CLEAR,
} from "../../actions/admin/integrations";
import { createReducer } from "../create";

const getIntegrations = createReducer(GET_INTEGRATIONS_LOADING, GET_INTEGRATIONS_SUCCESS, GET_INTEGRATIONS_FAILURE);
const postIntegration = createReducer(
  POST_INTEGRATION_LOADING,
  POST_INTEGRATION_SUCCESS,
  POST_INTEGRATION_FAILURE,
  POST_INTEGRATION_CLEAR,
);

export default {
  getIntegrations,
  postIntegration,
};
